import { API } from 'aws-amplify';
import React, { useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getAuthToken } from '../../utils/AuthUtils';
import './styles.scss';
import UserLookupInput from '../../CommonComponents/UserLookupInputComponent/UserLookupInput';
import ToolTipWrapper from "../../CommonComponents/ToolTipComponent/ToolTipWrapper"

const DIRECTOR = "Director";
const EXECUTIVE = "Executive";
const COMPENSATION_COMMITTEE = "Compensation Committee";
const NOM_AND_GOV_COMMITTEE = "Nom and Gov Committee";
const MANAGEMENT = "Management";
const OTHER = "Other";

const IndependenceCheckModal = ({ show, onClose, data, onRecordUpdated, mode }) => {
    const [independenceCheckRecord, setIndependenceCheckRecord] = useState(data);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [displayError, setDisplayError] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);
    const [clientOwnerValid, setClientOwnerValid] = useState(true);
    const [roleValid, setRoleValid] = useState(true);
    const [principalOccupationValid, setPrincipalOccupationValid] = useState(true);
    const [compCommitteeValid, setCompCommitteeValid] = useState(true);
    const [nomGovCommitteeValid, setNomGovCommitteeValid] = useState(true);
    const [engagementTypeValid, setEngagementTypeValid] = useState(true);
    const [surveyParticipantValid, setSurveyParticipantValid] = useState(true);

    const [success, setSuccess] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);

    const handleClose = () => {
        setDisplayError(false);
        setDisplaySuccess(false);
        setError("");
        setSuccess("");
        onClose();
    }

    const handleSetClientOwnerLookupTerm = (clientOwnerLookupTerm) => {
        if (mode === 'edit') {
            setIndependenceCheckRecord({ ...independenceCheckRecord, clientOwner: clientOwnerLookupTerm });
        }
    }

    const handleSetSelectedClientOwnerNameRecord = (clientOwnerNameRecord) => {
        if (mode === 'edit') {
            setIndependenceCheckRecord({ ...independenceCheckRecord, clientOwner: clientOwnerNameRecord.userName });
        }
    }

    const handleChangeEmployeeName = (e) => {
        if (mode === 'edit') {
            const name = e.target.value;
            setIndependenceCheckRecord({ ...independenceCheckRecord, relevantCompanyInsider: name });
        }
    }

    const handleChangeRole = (role) => {
        if (mode === 'edit') {
            setIndependenceCheckRecord({ ...independenceCheckRecord, role: role });
        }
    }

    const handleChangePrincipalOccupation = (e) => {
        if (mode === 'edit') {
            const occupation = e.target.value;
            setIndependenceCheckRecord({ ...independenceCheckRecord, principalOccupation: occupation });
        }
    }

    const handleCompCommitteeChange = (value) => {
        if (mode === 'edit') {
            if (value === "true") {
                setIndependenceCheckRecord({ ...independenceCheckRecord, compensationCommitteeMember: true })
            } else {
                setIndependenceCheckRecord({ ...independenceCheckRecord, compensationCommitteeMember: false })
            }
        }
    }

    const handleNominatingCommitteeChange = (value) => {
        if (mode === 'edit') {
            if (value === "true") {
                setIndependenceCheckRecord({ ...independenceCheckRecord, nominatingGovernanceCommitteeMember: true })
            } else {
                setIndependenceCheckRecord({ ...independenceCheckRecord, nominatingGovernanceCommitteeMember: false })
            }
        }
    }

    const handleUpdateNotes = (e) => {
        if (mode === 'edit') {
            const newNotes = e.target.value;
            setIndependenceCheckRecord({ ...independenceCheckRecord, notes: newNotes });
        }
    }

    const handleEngagementTypeChange = (value) => {
        if (mode === 'edit') {
            setIndependenceCheckRecord({  ...independenceCheckRecord, engagementType: value })
        }
    }

    const handleSurveyParticipantChange = (value) => {
        if (mode === 'edit') {
            if (value === "true") {
                setIndependenceCheckRecord({  ...independenceCheckRecord, surveyParticipant: true})
            } else {
                setIndependenceCheckRecord({  ...independenceCheckRecord, surveyParticipant: false})
            }
        }
    }

    const validateForm = () => {
        let valid = true;

        if (!independenceCheckRecord.clientOwner || independenceCheckRecord.clientOwner.length < 3) {
            setClientOwnerValid(false);
            setIsFormValid(false);
            valid = false;
        }

        if (!independenceCheckRecord.role || independenceCheckRecord.role.length < 3) {
            setRoleValid(false);
            setIsFormValid(false);
            valid = false;
        }

        if (!independenceCheckRecord.principalOccupation || independenceCheckRecord.principalOccupation.length < 3) {
            setPrincipalOccupationValid(false);
            setIsFormValid(false);
            valid = false;
        }

        if (independenceCheckRecord.compensationCommitteeMember === undefined) {
            setCompCommitteeValid(false);
            setIsFormValid(false);
            valid = false;
        }

        if (independenceCheckRecord.nominatingGovernanceCommitteeMember === undefined) {
            setNomGovCommitteeValid(false);
            setIsFormValid(false);
            valid = false;
        }

        if (independenceCheckRecord.engagementType === undefined) {
            setEngagementTypeValid(false);
            setIsFormValid(false);
            valid = false;
        }

        if (independenceCheckRecord.surveyParticipant === undefined) {
            setSurveyParticipantValid(false);
            setIsFormValid(false);
            valid = false;
        }

        return valid
    }

    const formatDateTime = (dateStr) => {
        if (dateStr && dateStr.length > 0) {
            const formattedDate = new Date(dateStr);
            return formattedDate.toLocaleString();
        }
        return "";
    }

    const formatDate = (dateStr) => {
        if (dateStr && dateStr.length > 0) {
            const formattedDate = new Date(dateStr);
            return formattedDate.toLocaleDateString();
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const token = await getAuthToken();

        try {
            if (!validateForm()) {
                return;
            }

            setLoading(true);
            await API.put("AnnualCompensations", "/independence-checks", {
                body: independenceCheckRecord,
                headers: {
                    "Content-Type": "application/json",
                    "Token": token
                }
            }).then(response => {
                setLoading(false);
                setDisplaySuccess(true);
                setSuccess(response.message);
                onRecordUpdated();
                handleClose();
            }).catch(error => {
                console.log(error);
                setLoading(false);
                setDisplayError(true);
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred while modifying independence check data. Please try again later.");
                }
            })
        } catch (error) {
            console.log(error);
            setLoading(false);
            setDisplayError(true);
            setError("Exception occurred updating independence check");
        }
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} size='xl' centered>
                <div className="independence-check-record-container">
                    <Form noValidate validated={isFormValid} onSubmit={(e) => handleUpdate(e)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{mode === 'edit' ? "Edit Independence Check" : "Independence Check Details"}</Modal.Title>
                            {mode === 'view' ? <ToolTipWrapper
                                id="audit-details"
                                text={data.modifiedBy ? `Created by ${data.createdBy} on ${formatDateTime(data.createdOn)}. Modified by ${data.modifiedBy} on ${formatDateTime(data.modifiedOn)}.` : `Created by ${data.createdBy} on ${formatDateTime(data.createdOn)}.`}
                                element={<i className="fa fa-question-circle"></i>}
                                placement={"right"}
                                style={{ marginLeft: "0.5rem" }}
                            /> : ''}
                        </Modal.Header>
                        <Modal.Body>
                            <Alert show={displayError} variant="danger" dismissible onClose={() => setDisplayError(false)}>
                                {error}
                            </Alert>
                            <Alert show={displaySuccess} variant="success" dismissible onClose={() => setDisplaySuccess(false)}>
                                {success}
                            </Alert>
                            {(loading && independenceCheckRecord) ? <div className="loading">Loading...<output><Spinner as="span" animation="grow" size="sm" aria-hidden="true" /></output></div> :
                                <div className="independence-check-record">
                                    <div className="record-sub-details">
                                        <h5 className="subtitle">Company Details</h5>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={independenceCheckRecord.companyName}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="ticker">
                                                    <Form.Label>Ticker</Form.Label>
                                                    <Form.Control type="text" value={data.ticker} readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group control="companyId">
                                                    <Form.Label>Company Id</Form.Label>
                                                    <Form.Control type="text" value={data.companyId} readOnly />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                {mode === 'edit' ? <UserLookupInput
                                                    value={independenceCheckRecord.clientOwner}
                                                    handleValueChange={handleSetClientOwnerLookupTerm}
                                                    noValidate={false}
                                                    isValueValid={clientOwnerValid}
                                                    inputLabel={"FWC Client Owner Name"}
                                                    handleUserRecordSelection={handleSetSelectedClientOwnerNameRecord}
                                                    customErrorMessage={"Please enter Client Owner"}
                                                /> : <Form.Group controlId="clientOwner">
                                                    <Form.Label>FWC Client Owner</Form.Label>
                                                    <Form.Control type="text" value={data.clientOwner} readOnly />
                                                </Form.Group>}

                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Effective Date</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={formatDate(independenceCheckRecord.effectiveDate)}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="year">
                                                    <Form.Label>Year</Form.Label>
                                                    <Form.Control type="text" value={data.year} readOnly />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                            <Form.Group controlId="engagementType">
                                                <Form.Label>Engagement Type</Form.Label>
                                                <Form.Select required isInvalid={!engagementTypeValid} value={independenceCheckRecord.engagementType} onChange={(e) => handleEngagementTypeChange(e.target.value)} disabled={mode === 'view'}>
                                                    <option value={COMPENSATION_COMMITTEE}>Compensation Committee</option>
                                                    <option value={NOM_AND_GOV_COMMITTEE}>Nom and Gov Committee</option>
                                                    <option value={MANAGEMENT}>Management</option>
                                                    <option value={OTHER}>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                            <Form.Group controlId="surveyParticipant">
                                                <Form.Label>Survey Participant</Form.Label>
                                                <Form.Select required isInvalid={!surveyParticipantValid} value={independenceCheckRecord.surveyParticipant} onChange={(e) => handleSurveyParticipantChange(e.target.value)} disabled={mode === 'view'}>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Form.Select>
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='record-sub-details'>
                                        <h5 className="subtitle">Company Insider Details</h5>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group controlId="relevantCompanyInsider">
                                                    <Form.Label>Relevant Company Insider</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={independenceCheckRecord.relevantCompanyInsider}
                                                        onChange={handleChangeEmployeeName}
                                                        readOnly={mode === 'view'}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="role">
                                                    <Form.Label>Role</Form.Label>
                                                    <Form.Select
                                                        required isInvalid={!roleValid}
                                                        value={independenceCheckRecord.role}
                                                        onChange={(e) => handleChangeRole(e.target.value)}
                                                        disabled={mode === 'view'}>
                                                        <option value={DIRECTOR}>Director</option>
                                                        <option value={EXECUTIVE}>Executive</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter Role
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="principalOccupation">
                                                    <Form.Label>Principal Occupation</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={independenceCheckRecord.principalOccupation}
                                                        onChange={handleChangePrincipalOccupation}
                                                        required
                                                        isInvalid={!principalOccupationValid}
                                                        readOnly={mode === 'view'}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter Principal Occupation
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group controlId="compensationCommitteeMember">
                                                    <Form.Label>Compensation Committee Member</Form.Label>
                                                    <Form.Select required isInvalid={!compCommitteeValid} value={independenceCheckRecord.compensationCommitteeMember} onChange={(e) => handleCompCommitteeChange(e.target.value)} disabled={mode === 'view'}>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select Compensation Committee Member
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="nominatingGovernanceCommitteeMember">
                                                    <Form.Label>Nominating Governance Member</Form.Label>
                                                    <Form.Select required isInvalid={!nomGovCommitteeValid} value={independenceCheckRecord.nominatingGovernanceCommitteeMember} onChange={(e) => handleNominatingCommitteeChange(e.target.value)} disabled={mode === 'view'}>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select Nominating Governance Committee Member
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    {mode === 'view' ? <div className='record-sub-details'>
                                        <h5 className="subtitle">Relationship Details</h5>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group controlId="otherBoardAffiliation">
                                                    <Form.Label>Other Board Affiliation</Form.Label>
                                                    <Form.Control type="text" value={data.otherBoardAffiliation} readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="fwcRelationship">
                                                    <Form.Label>FWC Consultant</Form.Label>
                                                    <Form.Control type="text" value={data.fwcRelationship} readOnly />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                        : ""}
                                    <Row>
                                        <Form.Group controlId="notes">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control
                                                type="textarea"
                                                value={independenceCheckRecord.notes}
                                                onChange={handleUpdateNotes}
                                                readOnly={mode === 'view'}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            {mode === 'edit' ? <Button variant="primary" disabled={loading} type="submit">Save</Button> : ""}
                        </Modal.Footer>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

IndependenceCheckModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    onRecordUpdated: PropTypes.func,
    mode: PropTypes.oneOf(['edit', 'view']).isRequired
}

export default IndependenceCheckModal;