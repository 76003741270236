import { Analytics } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Modal, Row } from 'react-bootstrap';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { DATA_TYPES, NORMALIZED_COMPENSATION_DB_ATTR_NAME_MAP, NORMALIZED_COMPENSATION_GRID_COLUMNS } from '../../Constants';
import { FilterContext } from '../../Contexts/FilterContext';
import { ToastContext } from '../../Contexts/ToastProvider';
import { ColumnFilterRenderer } from '../../Renderers/ColumnFilterRenderer';
import { CurrencyFormatter, HyperLinkFormatter, NumberFormatter, PercentageFormatter } from '../../utils/GridColumnFormatters';
import { copyValueToClipboard, filterRows, inputStopPropagation, sortComps } from '../../utils/GridUtils';
import { exportToXlsx } from '../../utils/exportUtils';
import './styles.scss';

const SUMMARY = "Summary";

const resolveColumnKey = (colDef) => colDef.isFilterable ? colDef.filterableColumnName : resolveNonFilterableColumnKey(colDef);

const calculateDefaultColWidth = (colDef) => {
	return colDef.name.length > 20 ? colDef.name.length * 9 : 160;
}

function resolveNonFilterableColumnKey(colDef) {
	return NORMALIZED_COMPENSATION_GRID_COLUMNS.includes(colDef.name) ? NORMALIZED_COMPENSATION_DB_ATTR_NAME_MAP.get(colDef.name) : colDef.id;
}

function buildGridColumns(columnDefinitions, setFilters) {
	const selectCol = SelectColumn;
	const gridColumns = [selectCol]

	columnDefinitions.forEach((colDef, index) => {
		const isStandardColumn = NORMALIZED_COMPENSATION_GRID_COLUMNS.includes(colDef.name);
		const colKey = resolveColumnKey(colDef);
		const columDefWithFilter = {
			name: colDef.name,
			key: colKey,
			sortable: true,
			resizable: true,
			type: colDef.dataType,
			sortDescendingFirst: false,
			headerCellClass: 'filter-cell'
		}

		if (columnDefinitions.length >= 15) {
			columDefWithFilter.width = calculateDefaultColWidth(colDef);
		}

		if (index < 4) {
			columDefWithFilter.frozen = true;
		}

		if (index === 0) {
			columDefWithFilter.summaryFormatter = () => {
				return <p>Total</p>
			}
		}

		if (index === 1) {
			columDefWithFilter.summaryFormatter = ({ row }) => {
				return <strong>{row.totalCount} records</strong>
			}
		}

		if (index === 2) {
			columDefWithFilter.summaryFormatter = () => {
				return <p>Selected records</p>
			}
		}

		if (index === 3) {
			columDefWithFilter.summaryFormatter = ({ row }) => {
				return <strong>{row.selectedRowCount} records</strong>
			}
		}
		applyColumnFilterRenderer(columDefWithFilter, colKey, setColumnFilters, colDef);
		if (!isStandardColumn) {
			if (colDef.name.includes('%')) {
				columDefWithFilter.formatter = (props) => {
					return <PercentageFormatter key={colKey} value={props.row[colKey]} />
				}
			} else if (colDef.name.includes('$')) {
				columDefWithFilter.formatter = (props) => {
					return <CurrencyFormatter key={colKey} value={props.row[colKey]} />
				}
			} else if (colDef.dataType === DATA_TYPES.NUMBER) {
				columDefWithFilter.formatter = (props) => {
					return <NumberFormatter key={colKey} value={props.row[colKey]} />
				}
			} else {
				columDefWithFilter.formatter = (props) => {
					return <HyperLinkFormatter key={colKey} value={props.row[colKey]} />
				}
			}
		}
		gridColumns.push(columDefWithFilter);
	});

	return gridColumns;

	function setColumnFilters(filters, colKey, e, colDef) {
		Analytics.record({
			name: 'SummaryGridColumnFilter',
			attributes: {
				columnName: colDef.name,
				colKey: colKey,
				filterValue: e.target.value
			}
		});
		return setFilters({
			...filters,
			[colKey]: e.target.value
		});
	}
}

function applyColumnFilterRenderer(columDefWithFilter, colKey, setColumnFilters, colDef) {
	columDefWithFilter.headerRenderer = (p) => (
		<ColumnFilterRenderer {...p}>
			{({ filters, ...rest }) => (
				<input
					key={colKey}
					{...rest}
					className='filter-renderer'
					value={filters[colKey]}
					onChange={(e) => setColumnFilters(filters, colKey, e, colDef)}
					placeholder='Type to filter'
					disabled={!filters.enabled}
					onKeyDown={inputStopPropagation} />
			)}
		</ColumnFilterRenderer>
	);
}

function createFilterProps(columnDefinitions, enabled) {
	const filterProps = { enabled };
	if (columnDefinitions !== null) {
		columnDefinitions.forEach(colDef => {
			if (colDef.isFilterable === true && colDef.filterableColumnName) {
				filterProps[colDef.filterableColumnName] = '';
			} else if (NORMALIZED_COMPENSATION_DB_ATTR_NAME_MAP.get(colDef.name) !== undefined) {
				const colKey = NORMALIZED_COMPENSATION_DB_ATTR_NAME_MAP.get(colDef.name);
				filterProps[colKey] = '';
			} else {
				filterProps[colDef.id] = '';
			}
		});
	}
	return filterProps;
}

export default function CompensationGrid({ compensations, columnDefinitions, rowKeyGetter, selectedRows, onSelectedRowsChange, templateTitle, handleRetrieveSheetData, handleGridResize, sheetNames = undefined, selectedSheetName = SUMMARY, allowGridToExcel = true, isGridExpanded = false }) {
	const [sortColumns, setSortColumns] = useState([]);
	const [filters, setFilters] = useState(createFilterProps(columnDefinitions, true));
	const [expandGrid, setExpandGrid] = useState(isGridExpanded);
	const [filterInUse, setFilterInUse] = useState(false);
	const [hasSearchResults, setHasSearchResults] = useState(!!compensations?.length > 0);

	const [columns, setColumns] = useState(buildGridColumns(columnDefinitions, setFilters));

	const [showExpanderHeaderFilter, setShowExpanderHeaderFilter] = useState(true);

	const [availableSheets, setAvailableSheets] = useState([SUMMARY]);

	const { showToast } = useContext(ToastContext);

	useEffect(() => {
		setFilters(createFilterProps(columnDefinitions, showExpanderHeaderFilter));
	}, [showExpanderHeaderFilter, columnDefinitions]);

	useEffect(() => {
		setColumns(buildGridColumns(columnDefinitions, setFilters));
	}, [columnDefinitions, expandGrid]);

	useEffect(() => {
		if (Array.isArray(compensations)) {
			setHasSearchResults(compensations?.length > 0)
		} else {
			setHasSearchResults(false);
		}
	}, [compensations])

	useEffect(() => {
		const checkAnyFiltersInUse = () => {
			if (selectedRows.size > 0) {
				setFilterInUse(true);
				return;
			}
			const filterValues = Object.values(filters);
			const areAnyFiltersInUse = filterValues.some(val => val && val?.length > 0);
			setFilterInUse(areAnyFiltersInUse);
		}
		checkAnyFiltersInUse();
	}, [filters, selectedRows.size])

	useEffect(() => {
		setSortColumns([])
	}, [compensations]);

	useEffect(() => {
		const sheets = [SUMMARY];
		if (sheetNames && sheetNames.length > 0) {
			sheets.push(...sheetNames);
		}
		setAvailableSheets(sheets)
	}, [sheetNames])

	const onSortColumnChange = useCallback((sortColumns) => {
		setSortColumns(sortColumns.slice(-2));
	}, []);

	const sortedRows = useMemo(() => {
		const filteredRows = filterRows(filters, compensations);
		if (sortColumns.length === 0) {
			if (filters !== null) {
				return filteredRows;
			} else {
				return compensations;
			}
		}

		return sortComps(filteredRows, sortColumns, columns);
	}, [sortColumns, compensations, columns, filters]);

	const summaryRows = useMemo(() => {
		const summaryRow = {
			totalCount: sortedRows.length,
			selectedRowCount: selectedRows.size
		}
		return [summaryRow]
	}, [sortedRows, selectedRows]);

	const gridRef = useRef(null);

	const handleGridShortcut = (e) => {
		if (filters.enabled)
			return;
		if (gridRef.current) {
			if (e.key.toLowerCase() === "u") {
				gridRef.current.scrollToRow(0);
			} else if (e.key.toLowerCase() === "d") {
				gridRef.current.scrollToRow(compensations.length - 1);
			}
		}
	}

	const toggleHeaderFilters = () => {
		setShowExpanderHeaderFilter(!showExpanderHeaderFilter);
	}

	const clearGridFilters = () => {
		onSelectedRowsChange(new Set());
		setFilters(createFilterProps(columnDefinitions, true));
	}

	const toggleGridExpansion = () => {
		setExpandGrid(!expandGrid);
		handleGridResize(!expandGrid);
	}

	const defaultSizeGrid = <DataGrid
		onKeyDown={handleGridShortcut}
		ref={gridRef}
		className={'grid rdg-light filter-container'}
		headerRowHeight={showExpanderHeaderFilter ? 90 : 35}
		columns={columns}
		summaryRows={summaryRows}
		summaryRowHeight={40}
		selectedRows={selectedRows}
		onSelectedRowsChange={onSelectedRowsChange}
		sortColumns={sortColumns}
		onSortColumnsChange={onSortColumnChange}
		rows={sortedRows}
		rowKeyGetter={rowKeyGetter}
		cellNavigationMode={"CHANGE_ROW"}
		onCopy={(e) => copyValueToClipboard(e, showToast)}
	/>;

	const sheets = (
		<div className='grid-tool-bar' key='grid-tool-bar'>
			<div className='grid-tools'>
				<Row>
					<div className="available-sheets">
						{(availableSheets && availableSheets.length > 0) ? availableSheets.map(sheetName => {
							return <Badge key={sheetName} pill as="button" onClick={() => handleRetrieveSheetData(sheetName)} bg={(sheetName === selectedSheetName) ? 'primary' : 'secondary'}>{sheetName}</Badge>
						}) : null}
					</div>
				</Row>
			</div>
		</div>
	);

	const toolBar = (
		<div className='grid-tool-bar' key='grid-tool-bar'>
			<div className='grid-tools'>
				<Button title='Toggle Grid Header Filters' variant='secondary' disabled={!hasSearchResults} onClick={toggleHeaderFilters}>
					<i className={`fa ${showExpanderHeaderFilter ? 'fa-sort-amount-up-alt' : 'fa-sort-amount-down-alt'}`}></i>
				</Button>
				<Button title='Export grid to excel' variant='secondary' disabled={!allowGridToExcel || !hasSearchResults} onClick={() => exportToXlsx(defaultSizeGrid, templateTitle)}>
					<i className="fa fa-file-csv"></i>
				</Button>
				<Button title='Expand Grid' variant='secondary' disabled={!hasSearchResults} onClick={() => toggleGridExpansion()}>
					<i className={`fa ${expandGrid ? 'fa-compress' : 'fa-expand'}`}></i>
				</Button>
				<Button title='Clear Filters' variant='secondary' disabled={!filterInUse || !hasSearchResults} onClick={clearGridFilters}>
					<i className="fa fa-times"></i>
				</Button>
			</div>
		</div>
	);

	return (
		<div className='grid-container' key='grid-container'>
			{sheets}
			{hasSearchResults > 0 && columnDefinitions?.length > 0 ? <FilterContext.Provider value={filters}>
				{toolBar}
				{defaultSizeGrid}
				<Modal
					show={expandGrid}
					onHide={() => toggleGridExpansion()}
					size="xl"
					fullscreen="lg-down"
					className='expanded-grid-modal containter-md'
				>
					<Modal.Header closeButton>
						<Modal.Title>{templateTitle}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="grid-container mt-0 mb-2">
							{toolBar}
							{defaultSizeGrid}
						</div>
					</Modal.Body>
				</Modal>
			</FilterContext.Provider> : ""}
		</div>
	);
}

CompensationGrid.propTypes = {
	compensations: PropTypes.array,
	columnDefinitions: PropTypes.array,
	rowKeyGetter: PropTypes.func,
	selectedRows: PropTypes.object,
	onSelectedRowsChange: PropTypes.func,
	templateTitle: PropTypes.string,
	allowGridToExcel: PropTypes.bool,
	sheetNames: PropTypes.array,
	selectedSheetName: PropTypes.string,
	handleRetrieveSheetData: PropTypes.func,
	handleGridResize: PropTypes.func,
	isGridExpanded: PropTypes.bool
};