import { API } from 'aws-amplify';
import { getAuthToken } from '../utils/AuthUtils';
import { Alert, Form, Button, Spinner, Col, Row } from "react-bootstrap";
import GenericGridComponent from '../GenericGridComponent/GenericGridComponent';
import { useState, useEffect } from 'react';
import IndependenceCheckModal from './IndependenceCheckModal/IndependenceCheckModal';
import { exportToCSV } from '../utils/exportUtils';
import "./styles.scss";
import ClientMetadataLookupInput from '../CommonComponents/ClientMetadataLookupInputComponent/ClientMetadataLookupInput';
import UserLookupInput from '../CommonComponents/UserLookupInputComponent/UserLookupInput';
import CreateIndependenceCheckModal from './CreateIndependenceCheckModal/CreateIndependenceCheckModal';

const COMPANY = "company";
const EMPLOYEE = "employee";
const CONSULTANT = "consultant";

const IndependenceCheckConsole = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [displayResults, setDisplayResults] = useState(false);

    const [error, setError] = useState("");
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);

    const [selectedSearchType, setSelectedSearchType] = useState(COMPANY);

    const [searchPhrase, setSearchPhrase] = useState("");
    const [searchPhraseInvalid, setSearchPhraseInvalid] = useState(false);

    const [hasSearchPhraseError, setHasSearchPhraseError] = useState(false);

    const [selectedIndependenceCheck, setSelectedIndependenceCheck] = useState(null);
    const [showCreateIndependenceCheckModal, setShowCreateIndependenceCheckModal] = useState(false);

    const [showIndependenceCheckModal, setShowIndependenceCheckModal] = useState(false);
    const [independenceCheckMode, setIndependenceCheckMode] = useState('edit');

    const [currentPage, setCurrentPage] = useState(1);

    const actions = row => (
        <div className='row-actions'>
            <Button variant='primary edit-billing-record-btn' size='sm' className='mr-2' onClick={() => handleEditRecord(row)}>
                <i className='fa fa-edit' title='Edit'></i>
            </Button>
            <Button variant="primary view-request-btn" size="sm" className="mr-2" onClick={() => handleViewIndependenceCheck(row)}>
                <i className="fa fa-eye"></i>
            </Button>
        </div>
    );

    const handleEditRecord = independenceCheckRecord => {
        setSelectedIndependenceCheck(independenceCheckRecord);
        setShowIndependenceCheckModal(true);
        setIndependenceCheckMode('edit');
    }

    const handleViewIndependenceCheck = independenceCheckRecord => {
        setSelectedIndependenceCheck(independenceCheckRecord);
        setShowIndependenceCheckModal(true);
        setIndependenceCheckMode('view');
    }

    const columns = [
        {
            "key": "actions",
            "name": "Edit/View",
            "sortable": false,
            "filterable": false,
            "formatter": ({ row }) => (
                actions(row)
            ),
            "frozen": true,
            "minWidth": 120,
        },
        {
            "key": "companyName",
            "name": "Company Name",
            "sortable": true,
            "filterable": true,
            "frozen": true,
            "minWidth": 200,
        },
        {
            "key": "relevantCompanyInsider",
            "name": "Employee",
            "sortable": true,
            "filterable": true,
            "frozen": true,
            "minWidth": 150,
        },
        {
            "key": "fwcRelationship",
            "name": "FWC Consultant",
            "sortable": true,
            "filterable": true,
            "minWidth": 200,
            "frozen": true,
        },
        {
            "key": "role",
            "name": "Role",
            "sortable": true,
            "filterable": true,
            "minWidth": 100,
        },
        {
            "key": "principalOccupation",
            "name": "Principal Occupation",
            "sortable": true,
            "filterable": true,
            "minWidth": 200,
        },
        {
            "key": "otherBoardAffiliation",
            "name": "Other Board Affiliation",
            "sortable": true,
            "filterable": true,
            "minWidth": 200,
        },

        {
            "key": "compensationCommitteeMember",
            "name": "Compensation Committee Member",
            "sortable": true,
            "filterable": true,
            "formatter": ({ row }) => (row.compensationCommitteeMember ? "Yes" : "No"),
            "minWidth": 200,
        },
        {
            "key": "nominatingGovernanceCommitteeMember",
            "name": "Nominating Governance Member",
            "sortable": true,
            "filterable": true,
            "formatter": ({ row }) => (row.nominatingGovernanceCommitteeMember ? "Yes" : "No"),
            "minWidth": 150,
        }];

    const createOutputRows = (data) => {
        return data.map(independenceCheck => {
            return {
                "Ticker": independenceCheck.ticker,
                "Company Name": independenceCheck.companyName,
                "Company Id": independenceCheck.companyId,
                "Effective Date": independenceCheck.effectiveDate,
                "Year": independenceCheck.year,
                "Client Owner": independenceCheck.clientOwner,
                "Relevant Company Insider": independenceCheck.relevantCompanyInsider,
                "Role": independenceCheck.role,
                "Principal Occupation": independenceCheck.principalOccupation,
                "Compensation Committee Member": independenceCheck.compensationCommitteeMember ? "Yes" : "No",
                "Nominating Governance Committee Member": independenceCheck.nominatingGovernanceCommitteeMember ? "Yes" : "No",
                "Other Board Affiliation": independenceCheck.otherBoardAffiliation,
                "FWC Consultant": independenceCheck.fwcRelationship,
                "Notes": independenceCheck.notes,
                "Created By": independenceCheck.createdBy,
                "Created On": independenceCheck.createdOn,
                "Modified By": independenceCheck.modifiedBy,
                "Modified On": independenceCheck.modifiedOn
            }
        })
    }

    const handleSearch = async (searchPhrase, searchType) => {
        setLoading(true);
        setErrorDisplayStatus(false);
        setDisplayResults(false);
        setError("");

        const authToken = await getAuthToken();

        const url = `/independence-checks/${searchType}`;
        let queryStringParameters;

        if (searchType === COMPANY) {
            queryStringParameters = { companyName: searchPhrase } //company name is not stored in lowercase
        } else if (searchType === CONSULTANT) {
            queryStringParameters = { consultantName: searchPhrase.toLowerCase() }
        } else if (searchType === EMPLOYEE) {
            queryStringParameters = { employeeName: searchPhrase.toLowerCase() }
        }
        API.get('AnnualCompensations', url, {
            headers: {
                "Token": authToken
            },
            queryStringParameters
        }).then((response) => {
            setDisplayResults(true);
            setData(response);
            setLoading(false);
        }).catch((error) => {
            if (error !== undefined && error.response !== undefined &&
                error.response.data !== undefined && error.response.data.message !== undefined) {
                setError(error.response.data.message);
                setErrorDisplayStatus(true);
            }
            setDisplayResults(true);
            setData([]);
            setLoading(false);
        });
    }

    const handleSetSearchType = (searchType) => {
        setSelectedSearchType(searchType);
        setSearchPhrase("");
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setData([]);
            setLoading(false);
            setSearchPhrase("");
            setError("");
            setErrorDisplayStatus(false);
            setSelectedSearchType(COMPANY);
            setSearchPhraseInvalid(false);
            setHasSearchPhraseError(false);
            setSelectedIndependenceCheck(null);
            setShowCreateIndependenceCheckModal(false);
            setShowIndependenceCheckModal(false);
            setDisplayResults(false);
        }
        return () => { isMounted = false };
    }, []);

    return (
        <div className='independence-check-data'>
            <Form noValidate className="independence-check-form" onSubmit={e => e.preventDefault()}>
                <Row>
                    <Col md={2}>
                        <Form.Group className="mb-2" controlId="searchType">
                            <Form.Label>Search By</Form.Label>
                            <Form.Select
                                value={selectedSearchType}
                                onChange={(e) => handleSetSearchType(e.target.value)}
                            >
                                <option value={COMPANY}>Company Name</option>
                                <option value={EMPLOYEE}>Employee Name</option>
                                <option value={CONSULTANT}>FWC Consultant Name</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        {selectedSearchType === COMPANY ? <ClientMetadataLookupInput
                            value={searchPhrase}
                            handleValueChange={setSearchPhrase}
                            noValidate={true}
                            handleClientMetadataRecordSelection={(record) => setSearchPhrase(record.companyName)}
                            inputLabel="Company Name"
                            customErrorMessage="Company not found"
                        /> : ""}
                        {selectedSearchType === CONSULTANT ? <UserLookupInput
                            value={searchPhrase}
                            handleValueChange={setSearchPhrase}
                            displayProperty="userName"
                            noValidate={true}
                            handleUserRecordSelection={(record) => setSearchPhrase(record.userName)}
                            inputLabel="FWC Consultant Name"
                            customErrorMessage="Error retrieving consultants"
                        /> : ""}
                        {selectedSearchType === EMPLOYEE ? <Form.Group controlId="search-phrase">
                            <Form.Label>Employee Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Employee Name"
                                value={searchPhrase}
                                onChange={(e) => setSearchPhrase(e.target.value)}
                                isInvalid={searchPhraseInvalid} />
                            {hasSearchPhraseError ? <Alert variant="danger" className="search-phrase-error-alert" dismissible>
                                There was an error retrieving results. Please try again later.
                            </Alert> : null}
                        </Form.Group> : ""}
                    </Col>
                </Row>
                <div className='actions'>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() => handleSearch(searchPhrase, selectedSearchType)}>Search<i className="fa fa-search"></i>
                    </Button>
                    <Button variant="secondary" onClick={() => setShowCreateIndependenceCheckModal(true)}>
                        Create
                    </Button>
                </div>
            </Form>
            {selectedIndependenceCheck && <IndependenceCheckModal
                data={selectedIndependenceCheck}
                show={showIndependenceCheckModal}
                onClose={() => {
                    setShowIndependenceCheckModal(false);
                    setSelectedIndependenceCheck(null);
                }}
                onRecordUpdated={() => handleSearch(searchPhrase, selectedSearchType)}
                mode={independenceCheckMode}
            />}
            {
                <CreateIndependenceCheckModal
                    show={showCreateIndependenceCheckModal}
                    onClose={() => {
                        setShowCreateIndependenceCheckModal(false);
                    }}
                    onRecordCreated={(companyName) => {
                        setSelectedSearchType(COMPANY);
                        setSearchPhrase(companyName);
                        handleSearch(companyName, COMPANY);
                    }}
                />
            }
            <div className="independence-checks-grid">
                {errorDisplayStatus && <Alert style={{ marginTop: "1rem" }} varient="danger">{error}</Alert>}
                {loading ? <Spinner animation="border" variant="primary" className="spinner-blue" /> : ""}
                {displayResults && <GenericGridComponent
                    columns={columns}
                    data={data}
                    enableGridToCSV={true}
                    useCustomExportMethod={true}
                    customExportMethod={() => exportToCSV(data, createOutputRows, "Independence_Checks", "Independence Checks")}
                    fileName={"Independence_Checks"}
                    itemsName={"Independence Checks"}
                    selectedCurrentPage={currentPage}
                    onCurrentPageSelect={(page) => setCurrentPage(page)}
                />}
            </div>
        </div>
    )
};

export default IndependenceCheckConsole;