import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import MultiSelectDropDownInput from '../CommonComponents/MultiSelectDropDownComponent/MultiSelectDropDownInput';
import { EXEC_TITLES, MARKET_CAPS } from '../Constants';

export default function TemplateFilters({ disabled, filters, handleFilteringOptions, handleFilterUpdate, findMatchingOptions, toggleMultiSelectedOptions, selectedTemplateId, marketCap, handleMarketCapOptions, handleMarketCapUpdate, findMatchingMarketCapOptions, toggleMultiSelectedMarketCapOptions }) {
    return (
        <>
            {selectedTemplateId && selectedTemplateId !== "" ?
                <div>
                    <Row className="mt-1 pb-2">
                        <Col md={3}>
                            <Form.Group controlId='market-cap'>
                                <Form.Label>
                                    <span>Market Cap</span>
                                </Form.Label>
                                <MultiSelectDropDownInput
                                disabled={disabled}
                                options={marketCap.options}
                                toggleOption={({ id }) => toggleMultiSelectedMarketCapOptions(id)}
                                clearAll={() => handleMarketCapUpdate([])}
                                optionSearch={option => {
                                    if (option === null) return;

                                    if (option === "") {
                                        handleMarketCapOptions(MARKET_CAPS);
                                        return;
                                    }

                                    option = option.trim();
                                    const optionLower = option.toLowerCase();
                                    const matchedOptions = findMatchingMarketCapOptions(optionLower, marketCap.options);
                                    handleMarketCapOptions(matchedOptions);
                                }}
                                />
                            </Form.Group>
                        </Col>
                        {filters.length > 0 && <>{filters.slice(0, 4).map((filter, index) => <Col md={3} key={filter.fieldName}>
                            <Form.Group controlId={filter.fieldName}>
                                <Form.Label>
                                    <span>{filter.fieldName}</span>
                                </Form.Label>
                                <MultiSelectDropDownInput
                                    disabled={disabled}
                                    options={filter.options}
                                    toggleOption={({ id }) => toggleMultiSelectedOptions(id, filter)}
                                    clearAll={() => handleFilterUpdate(filter, [])}
                                    optionSearch={option => {
                                        if (option === null) return;

                                        if (option === "") {
                                            handleFilteringOptions(filter, EXEC_TITLES);
                                            return;
                                        }

                                        option = option.trim();
                                        const optionLower = option.toLowerCase();
                                        const matchedOptions = findMatchingOptions(optionLower, filter.options);
                                        handleFilteringOptions(filter, matchedOptions);
                                    }} />
                            </Form.Group>
                        </Col>)}</>}
                    </Row>
                    {filters.length > 4 &&
                        <Row>
                            {filters.slice(4).map(filter => (
                                <Col md={4} key={filter.fieldName}>
                                    <Form.Group controlId={filter.fieldName}>
                                        <Form.Label>{filter.fieldName}</Form.Label>
                                        <Form.Control as="select" onChange={(e) => handleFilterUpdate(filter, e.target.value)} disabled={disabled}>
                                            <option>Loading...</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>))}
                        </Row>}
                </div> : ""}
        </>);
}
