import React from 'react';
import { Alert, Nav } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import UsageReportTab from './UsageReportTab/UsageReportTab';
import TemplateDefinitionTab from './TemplateDefinitionsTab/TemplateDefinitionTab';
import SurveySubmissionTab from './SurveySubmissionsTab/SurveySubmissions';
import ClientMetaDataTab from './ClientMetaDataTab/ClientMetaData'
import BillingDataTab from './BillingDataTab/BillingDataTab';

import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

export const USAGE_REPORT_TAB = "usage-report";
export const TEMPLATE_DEFINITIONS_TAB = "templates";
export const SURVEY_SUBMISSIONS_TAB = "survey-submissions";
export const CLIENT_METADATA_TAB = "client-metadata";
export const BILLING_DATA_TAB = "billing-data";

const AdminConsole = () => {
    const { tabName } = useParams();
    const navigate = useNavigate();

    const renderActiveTab = () => {
        switch (tabName) {
            case USAGE_REPORT_TAB:
                return (<UsageReportTab />);
            case TEMPLATE_DEFINITIONS_TAB:
                return (<TemplateDefinitionTab />);
            case SURVEY_SUBMISSIONS_TAB:
                return (<SurveySubmissionTab />);
            case CLIENT_METADATA_TAB:
                return (<ClientMetaDataTab />);
            case BILLING_DATA_TAB:
                return (<BillingDataTab />);
            default:
                return (<Alert variant="danger">Invalid tab name</Alert>);
        }
    }

    return (
        <div className="admin-console">
            <Nav justify variant="tabs" defaultActiveKey={tabName} onSelect={(selectedTab) => navigate("/admin/" + selectedTab)}>
                <Nav.Item>
                    <Nav.Link key={USAGE_REPORT_TAB} active={tabName === USAGE_REPORT_TAB} eventKey={USAGE_REPORT_TAB}>Usage Report</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={TEMPLATE_DEFINITIONS_TAB} active={tabName === TEMPLATE_DEFINITIONS_TAB} eventKey={TEMPLATE_DEFINITIONS_TAB}>Template Definitions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={SURVEY_SUBMISSIONS_TAB} active={tabName === SURVEY_SUBMISSIONS_TAB} eventKey={SURVEY_SUBMISSIONS_TAB}>Survey Submissions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={CLIENT_METADATA_TAB} active={tabName === CLIENT_METADATA_TAB} eventKey={CLIENT_METADATA_TAB}>Client Metadata</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={BILLING_DATA_TAB} active={tabName === BILLING_DATA_TAB} eventKey={BILLING_DATA_TAB}>Billing Data</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="main-content-area">{renderActiveTab()}</div>
        </div>
    );
}

export default AdminConsole;