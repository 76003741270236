import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import './styles.scss';
import { getAuthToken } from '../../utils/AuthUtils';
import { Alert, Form, Button, Col, Row, Badge, Modal } from 'react-bootstrap';
import ClientMetadataLookupInput from '../../CommonComponents/ClientMetadataLookupInputComponent/ClientMetadataLookupInput';
import UserLookupInput from '../../CommonComponents/UserLookupInputComponent/UserLookupInput';
import { v4 as uuidv4 } from 'uuid';

const DIRECTOR = "Director";
const EXECUTIVE = "Executive";
const COMPENSATION_COMMITTEE = "Compensation Committee";
const NOM_AND_GOV_COMMITTEE = "Nom and Gov Committee";
const MANAGEMENT = "Management";
const OTHER = "Other";

const currentYear = new Date().getFullYear();

const CreateIndependenceCheckModal = ({ show, onClose, onRecordCreated }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [error, setError] = useState("");
    const [displayError, setDisplayError] = useState(false)

    const [companyName, setCompanyName] = useState("");
    const [ticker, setTicker] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [clientOwner, setClientOwner] = useState("");
    const [effectiveDate, setEffictiveDate] = useState("");
    const [year, setYear] = useState(currentYear);
    const [engagementType, setEngagementType] = useState(COMPENSATION_COMMITTEE);
    const [surveyParticipant, setSurveyParticipant] = useState(false);

    const [companyNameValid, setCompanyNameValid] = useState(true);
    const [effectiveDateValid, setEffictiveDateValid] = useState(true);
    const [clientOwnerValid, setClientOwnerValid] = useState(true);
    const [yearValid, setYearValid] = useState(true);

    const [isFormValid, setIsFormValid] = useState(false);

    const handleClose = () => {
        setData([]);
        setLoading(false);
        setSuccess("");
        setDisplaySuccess(false);
        setError("");
        setDisplayError(false);
        setCompanyName("");
        setTicker("");
        setCompanyId("");
        setClientOwner("");
        setEffictiveDate("");
        setEngagementType(COMPENSATION_COMMITTEE);
        setSurveyParticipant(false);
        setYear(currentYear);
        setCompanyNameValid(true);
        setEffictiveDateValid(true);
        setClientOwnerValid(true);
        setYearValid(true);
        setIsFormValid(false);
        onClose();
    }

    const addRecord = () => {
        const newData = [...data];
        const newRecord = {
            tempId: uuidv4(),
            relevantCompanyInsider: "",
            role: DIRECTOR,
            principalOccupation: "",
            compensationCommitteeMember: false,
            nominatingGovernanceCommitteeMember: false,
            otherBoardAffiliation: "",
            fwcRelationship: "",
            notes: ""
        }
        newData.push(newRecord);
        setData(newData);
    }

    const addCopy = () => {
        if (data.length > 0) {
            const newData = [...data];
            const lastRecord = newData[newData.length - 1];
            const copy = { ...lastRecord }
            newData.push(copy);
            setData(newData);
        }
    }

    const handleSetCompanyMetadata = (companyMetadata) => {
        if (!companyMetadata.companyName) {
            setCompanyNameValid(false);
            return;
        }
        setCompanyName(companyMetadata.companyName);

        if (companyMetadata.clientOwner) {
            setClientOwner(companyMetadata.clientOwner);
        }

        if (companyMetadata.nsId) {
            setCompanyId(companyMetadata.nsId);
        }
    }

    const handleEmployeeNameChange = (recordIndex, event) => {
        const newData = [...data];
        newData[recordIndex].relevantCompanyInsider = event.target.value;
        setData(newData);
    }

    const handleRoleChange = (recordIndex, event) => {
        const newData = [...data];
        newData[recordIndex].role = event.target.value;
        setData(newData);
    }

    const handlePrincipalOccupationChange = (recordIndex, event) => {
        const newData = [...data];
        newData[recordIndex].principalOccupation = event.target.value;
        setData(newData);
    }

    const handleCompCommitteChange = (recordIndex, event) => {
        const newData = [...data];
        newData[recordIndex].compensationCommitteeMember = event.target.checked;
        setData(newData);
    }

    const handleNominatingCommitteeChange = (recordIndex, event) => {
        const newData = [...data];
        newData[recordIndex].nominatingGovernanceCommitteeMember = event.target.checked;
        setData(newData);
    }

    const handleBoardAffiliationChange = (recordIndex, event) => {
        const newData = [...data];
        newData[recordIndex].otherBoardAffiliation = event.target.value;
        setData(newData);
    }

    const handleConsultantChange = (recordIndex, value) => {
        const newData = [...data];
        newData[recordIndex].fwcRelationship = value;
        setData(newData);
    }

    const handleNotesChange = (recordIndex, event) => {
        const newData = [...data];
        newData[recordIndex].notes = event.target.value;
        setData(newData);
    }

    const removeRecord = (recordIndex) => {
        setError(false);
        setDisplayError(false);

        const newData = [...data];

        if (recordIndex > -1) {
            newData.splice(recordIndex, 1);
            setData(newData);
        } else {
            setError("An error occurred removing record. Please try again later.");
            setDisplayError(true);
        }
    }

    const handleCreate = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const token = await getAuthToken();

        try {
            if (!validatedSubmission()) {
                return;
            }

            const requstBody = {
                companyName: companyName,
                ticker: ticker,
                companyId: companyId,
                clientOwner: clientOwner,
                effectiveDate: effectiveDate,
                year: year,
                engagementType: engagementType,
                surveyParticipant: surveyParticipant,
                records: data
            };
            setLoading(true);
            await API.post("AnnualCompensations", "/independence-checks", {
                body: requstBody,
                headers: {
                    "Content-Type": "application/json",
                    "Token": token
                }
            }).then(response => {
                setLoading(false);
                setSuccess(response.message);
                setDisplaySuccess(true);
                onRecordCreated(companyName);
                handleClose();
            }).catch(error => {
                console.log(error);
                setLoading(false);
                setDisplayError(true);
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred creating independence check data. Please try again later.")
                }
            })
        } catch (error) {
            console.log(error);
            setLoading(false);
            setDisplayError(true);
            if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred creating independence check data. Please try again later.")
            }
        }
    }

    const validatedSubmission = () => {
        let validated = true;

        if (!companyName || companyName.length < 2) {
            setCompanyNameValid(false);
            setIsFormValid(false);
            validated = false;
        }

        if (!clientOwner || clientOwner.length < 3) {
            setClientOwnerValid(false);
            setIsFormValid(false);
            validated = false;
        }

        if (!effectiveDate || effectiveDate.length < 3) {
            setEffictiveDateValid(false);
            setIsFormValid(false);
            validated = false;
        }

        if (!year) {
            setYear(false);
            setIsFormValid(false);
            validated = false;
        }

        for (const record of data) {
            if (!record.relevantCompanyInsider || record.relevantCompanyInsider.length < 3) {
                setIsFormValid(false);
                validated = false;
            }

            if (!record.principalOccupation || record.principalOccupation.length < 3) {
                setIsFormValid(false);
                validated = false;
            }

            if (!record.role || record.role.length < 3) {
                setIsFormValid(false);
                validated = false;
            }
        }
        setIsFormValid(true);
        return validated;
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setData([]);
            setLoading(false);
            setSuccess("");
            setDisplaySuccess(false);
            setError("");
            setDisplayError(false);
            setCompanyName("");
            setTicker("");
            setCompanyId("");
            setClientOwner("");
            setEffictiveDate("");
            setYear(currentYear);
            setEngagementType(COMPENSATION_COMMITTEE);
            setSurveyParticipant(false);
            setCompanyNameValid(true);
            setEffictiveDateValid(true);
            setClientOwnerValid(true);
            setYearValid(true);
            setIsFormValid(false);
        }
        return () => { isMounted = false };
    }, []);

    return (
        <Modal show={show} onHide={handleClose} size='xl' centered>
            <div className="create-independence-check-records-container">
                <Form noValidate validated={isFormValid} onSubmit={(e) => handleCreate(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Independence Checks</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert show={displayError} variant="danger" dismissible onClose={() => setDisplayError(false)}>
                            {error}
                        </Alert>
                        <Alert show={displaySuccess} variant="success" dismissible onClose={() => setDisplaySuccess(false)}>
                            {success}
                        </Alert>
                        <Row>
                            <Col md={4}>
                                <Form.Group contorlId="companyName">
                                    <ClientMetadataLookupInput
                                        value={companyName}
                                        handleValueChange={setCompanyName}
                                        noValidate={false}
                                        isValueValid={companyNameValid}
                                        handleClientMetadataRecordSelection={handleSetCompanyMetadata}
                                        inputLabel="Company Name"
                                        customErrorMessage="Company not found"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="ticker">
                                    <Form.Label>Ticker</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={ticker}
                                        onChange={(e) => setTicker(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="clientOwner">
                                    <UserLookupInput
                                        value={clientOwner}
                                        handleValueChange={setClientOwner}
                                        displayProperty="userName"
                                        noValidate={false}
                                        isValueValid={clientOwnerValid}
                                        handleUserRecordSelection={(record) => setClientOwner(record.userName)}
                                        inputLabel="Client Owner"
                                        customErrorMessage="Client Owner not found"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="effectiveDate">
                                    <Form.Label>Effective Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={effectiveDate}
                                        onChange={(e) => setEffictiveDate(e.target.value)}
                                        required
                                        isInvalid={!effectiveDateValid}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Effective Date
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="year">
                                    <Form.Label>Year</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                        required
                                        isInvalid={!yearValid}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Year
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="engagementType">
                                    <Form.Label>Engagement Type</Form.Label>
                                    <Form.Select
                                        type="select"
                                        required
                                        value={engagementType}
                                        onChange={(e) => setEngagementType(e.target.value)}

                                    >
                                        <option value={COMPENSATION_COMMITTEE}>Compensation Committe</option>
                                        <option value={NOM_AND_GOV_COMMITTEE}>Nom and Gov Committee</option>
                                        <option value={MANAGEMENT}>Management</option>
                                        <option value={OTHER}>Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4} style={{ display: "flex", alignItems: "center", marginTop: "1rem"}}>
                                <Form.Group controlId="surveyParticipant">
                                    <Form.Check type="checkbox" label="Survey Participant" checked={surveyParticipant} onChange={(e) => setSurveyParticipant(e.target.checked)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="independence-check-relationship-header">
                            <Row className="title-section">
                                <Col md={2} style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                    <div className="title">
                                        Relationships
                                    </div>
                                    <span className="relationship-count">
                                        <Badge variant="secondary" >{data.length}</Badge>
                                    </span>
                                </Col>
                                <Col md={10} style={{ display: "flex", justifyContent: "right" }}>
                                    <Button
                                        title="Copy last relationship"
                                        variant="secondary"
                                        disabled={loading}
                                        onClick={() => addCopy()}
                                        style={{ backgroundColor: 'white', color: '#0071CE ' }}
                                    >
                                        <i className='fa fa-copy'></i>
                                    </Button>
                                    <Button
                                        title="Add independence check relationship"
                                        variant="secondary"
                                        disabled={loading}
                                        onClick={() => addRecord()}
                                        style={{ borderRadius: "50%" }} >
                                        <i className="fa fa-plus" style={{ padding: "0.25rem" }}></i>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="variable-independenc-check-values">
                            {data.map((record, index) => {
                                return (
                                    <div className="independence-check-relationship" key={record.id ? record.id : record.tempId}>
                                        <Row >
                                            <Col md={1} className="count-indicator">
                                                <Badge variant="secondary">{index + 1}</Badge>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group controlId="relevantCompanyInsider">
                                                    <Form.Label>Relevant Company Insider</Form.Label>
                                                    <Form.Control type="text" required value={record.relevantCompanyInsider} onChange={(e) => handleEmployeeNameChange(index, e)} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter Relevant Company Insider
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="principalOccupation">
                                                    <Form.Label>Principal Occupation</Form.Label>
                                                    <Form.Control type="text" required value={record.principalOccupation} onChange={(e) => handlePrincipalOccupationChange(index, e)} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter Principal Occupation or N/A
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="otherBoardAfiliation">
                                                    <Form.Label>Other Board Affiliation</Form.Label>
                                                    <Form.Control type="text" value={record.otherBoardAffiliation} onChange={(e) => handleBoardAffiliationChange(index, e)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={1}>
                                            </Col>
                                            <Col md={3}>
                                                <UserLookupInput
                                                    value={record.fwcRelationship}
                                                    handleValueChange={(value) => handleConsultantChange(index, value)}
                                                    noValidate={true}
                                                    handleUserRecordSelection={(userRecord) => handleConsultantChange(index, userRecord.userName)}
                                                    inputLabel="FWC Relationship"
                                                    customErrorMessage="FWC Consultant not found"
                                                />
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group notes="notes">
                                                    <Form.Label>Notes</Form.Label>
                                                    <Form.Control type="text" value={record.notes} onChange={(e) => handleNotesChange(index, e)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={1}>
                                                <div className="delete-relationship-button">
                                                    <Button variant="danger" onClick={() => removeRecord(index)}>
                                                        <i className="fa fa-trash" style={{ padding: 0 }} />
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group controlId="role">
                                                    <Form.Label>Insider Role</Form.Label>
                                                    <Form.Select type="select" required value={record.role} onChange={(e) => handleRoleChange(index, e)}>
                                                        <option value={DIRECTOR}>Director</option>
                                                        <option value={EXECUTIVE}>Executive</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter Relevant Company Insider
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} style={{ display: "flex", alignItems: "center" }}>
                                                <div className="compensation-committee-checkbox" style={{ display: "flex" }}>
                                                    <Form.Group controlId="compensationCommitteeMember">
                                                        <Form.Check type="checkbox" label="Compensation Committee Member" checked={record.compensationCommitteeMember} onChange={(e) => handleCompCommitteChange(index, e)} />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={4} style={{ display: "flex", alignItems: "center" }}>
                                                <div className="nominating-governance-committee-checkbox" >
                                                    <Form.Group controlId="nominatingGovernanceCommitteeMember">
                                                        <Form.Check type="checkbox" label="Nominating Gov. Committee Member" checked={record.nominatingGovernanceCommitteeMember} onChange={(e) => handleNominatingCommitteeChange(index, e)} />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" disabled={loading} onClick={handleClose}>Close</Button>
                        <Button variant="primary" disabled={loading} type="submit">Create</Button>
                    </Modal.Footer>
                    <Form.Control.Feedback type="invalid">
                        Please provide missing inputs
                    </Form.Control.Feedback>
                </Form>
            </div>
        </Modal>
    )
}

CreateIndependenceCheckModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordCreated: PropTypes.func
}

export default CreateIndependenceCheckModal;