import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Amplify, Analytics } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminConsole from './AdminConsole/AdminConsole';
import CreateTemplate from './AdminConsole/CreateTemplate/CreateTemplate';
import EditTemplateDefinition from './AdminConsole/EditTemplateDefinition/EditTemplateDefinition';
import { QueryFilingsChat } from './AdminConsole/QueryFilingsChat/QueryFilingsChat';
import App from './App';
import './App.css';
import DownloadHistoryConsole from './DataDownloadHistoryConsole/DownloadHistoryConsole';
import AllDataRequests from './DataRequests/AllDataRequests/AllDataRequests';
import DataRequestSubmission from './DataRequests/DataRequestSubmission/DataRequestSubmission';
import MyDataRequests from './DataRequests/MyDataRequests/MyDataRequests';
import ExploreCompensationData from './ExploreCompensationDataPage/ExploreCompensationData';
import GroupVisibleComponent from './GroupVisibleComponent';
import SurveyDataConsole from './SurveyDataConsole/SurveyDataConsole';
import SurveyParticipantConsole from './SurveyParticipantConsole/SurveyParticipantConsole';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import IndependenceCheckConsole from './IndependenceCheckConsole/IndependenceCheckConsole';

const domNode = document.getElementById('root');
const root = createRoot(domNode);

const config = {
  API: {
    endpoints: [
      {
        name: "AnnualCompensations",
        endpoint: process.env.REACT_APP_ANNUAL_COMPENSATIONS_API,
        region: process.env.REACT_APP_ANNUAL_COMPENSATIONS_API_REGION
      },
      {
        name: "UploadCompensations",
        endpoint: process.env.REACT_APP_COMPENSATIONS_UPLOAD_API,
        region: process.env.REACT_APP_COMPENSATIONS_UPLOAD_API_REGION
      },
      {
        name: "UnrestrictedSurveyData",
        endpoint: process.env.REACT_APP_UNRESTRICTED_SURVEY_DATA_API,
        region: process.env.REACT_APP_UNRESTRICTED_SURVEY_DATA_API_REGION
      }
    ]
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_S3_BUCKET_REGION,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
    }
  }
};
Amplify.configure(config);

const analyticsConfig = {
  AWSPinpoint: {
    appId: process.env.REACT_APP_PINPOINT_APP_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    mandatorySignIn: false,
  }
};

Analytics.configure(analyticsConfig);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<App />}>
          <Route path="" element={<ExploreCompensationData />} />
          <Route path="admin/:tabName" element={
            <GroupVisibleComponent groups={["FWC_Admin"]}>
              <AdminConsole />
            </GroupVisibleComponent>
          } />
          <Route path="admin/templates/new" element={
            <GroupVisibleComponent groups={["FWC_Admin"]}>
              <CreateTemplate />
            </GroupVisibleComponent>
          } />
          <Route path="admin/templates/:templateId" element={
            <GroupVisibleComponent groups={["FWC_Admin"]}>
              <EditTemplateDefinition />
            </GroupVisibleComponent>
          } />
          <Route path="admin/chat" element={
            <GroupVisibleComponent groups={["FWC_Admin"]}>
              <QueryFilingsChat />
            </GroupVisibleComponent>
          } />
          <Route path="data-requests/create" element={<DataRequestSubmission />} />
          <Route path="data-requests/user" element={<MyDataRequests />} />
          <Route path="data-requests" element={
            <GroupVisibleComponent groups={["Data_Provider", "FWC_Admin"]}>
              <AllDataRequests />
            </GroupVisibleComponent>
          } />
          <Route path="download-history/:tabName" element={
            <DownloadHistoryConsole />
          } />
          <Route path="survey-data" element={
            <GroupVisibleComponent groups={["Data_Provider", "FWC_Admin"]}>
              <SurveyDataConsole />
            </GroupVisibleComponent>
          } />
          <Route path="independence-checks" element={
            <GroupVisibleComponent groups={["INDEPENDENCE_CHECKS"]}>
              <IndependenceCheckConsole />
            </GroupVisibleComponent>
          } />
        </Route>
        <Route path="*" element={<h1>Not Found</h1>} />
        <Route path="/ecs-survey/:participantId" element={
          <SurveyParticipantConsole />
        } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
